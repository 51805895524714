@import url("https://cdn.jsdelivr.net/npm/halfmoon@1.1.1/css/halfmoon-variables.min.css");
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700;800;900&display=swap");

body {
	font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell,
	"Helvetica Neue", sans-serif;
	color: #fff;
}

body.dark-mode {
	background-color: #252525;
}

.App {
	padding: 20px;
}

h1 {
	font-weight: bold;
	text-align: center;
	color: #fff;
}

.badge.bg-secondary {
	background-color: rgb(145, 145, 145) !important;
}

.school-icon {
	height: 35px;
	padding-right: 5px;
	user-select: none;
	-webkit-user-drag: none;
}

.team-seed {
	padding-left: 5px;
	padding-right: 5px;
	margin-right: 5px;
	border-radius: 3px;
}

h2 {
	padding-left: 30px;
	padding-right: 30px;
}

.dark-mode .card {
	background-color: #333;
}

.bg-dark-light {
	background-color: #252525 !important;
}

.flex {
	display: flex !important;
}

.josh-prediction {
	display: flex;
	padding-left: 7px;
	padding-right: 7px;
	margin-left: 7px;
	background-color: #1890ff;
	display: inline-block;
	border-radius: 50%;
	cursor: default;
	font-weight: bold;
}

.bracket-icon {
	height: 25px;
	margin-bottom: -6px;
	margin-right: 3px;
	user-select: none;
	-webkit-user-drag: none;
}

.correct {
	color: rgb(32, 136, 0);
}

.wrong {
	color: rgb(226, 0, 0);
	text-decoration: line-through;
}

ul > li {
	list-style: none
}